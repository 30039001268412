<template>
  <section class="order-query">
    <div class="d1">
      <div class="flex alignFlexEnd justifySpaceBetween">
        <div class="flex alignFlexEnd">
          <div>
            <label>查询条件</label>
            <el-select
              v-model="condition"
              placeholder="请选择"
              @change="changeCondition"
            >
              <el-option
                v-for="item in orderConditionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="mlr">
            <el-input
              :disabled="canBeInput"
              v-model.trim="formSearch[condition]"
              placeholder="选择查询条件后输入"
            ></el-input>
          </div>
          <div>
            <label>状态</label>
            <el-select v-model="formSearch.status" placeholder="请选择状态">
              <el-option
                v-for="item in orderStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
    </div>

    <Table
      :baseData="baseData"
      :tableHeader="tableHeader"
      @handleCurrent="handleCurrentChange"
      :isShowPagination="true"
      :total="total"
      :pageSize="formSearch.pageSize"
      :currentPage="formSearch.pageNo"
    >
      <el-table-column label="操作" width="80" fixed="right">
        <template slot-scope="scope">
          <div class="opration check">
            <span @click="openDetail(scope.row)">详情</span>
          </div>
        </template>
      </el-table-column>
    </Table>

    <BaseInfoDialog :item="baseInfoData" />
  </section>
</template>
<script>
import BaseInfoDialog from "@/components/customer/BaseInfoDialog.vue";
import { orderConditionList, orderStatusList } from "@/util/constant";
import API from "@/api/api_query.js";

export default {
  name: "OrderQuery",
  components: {
    BaseInfoDialog,
  },
  data() {
    return {
      orderConditionList,
      orderStatusList,
      condition: undefined,
      formSearch: {
        pageNo: 1,
        pageSize: 10,
        orderId: undefined,
        appId: undefined,
        workbenchUser: undefined,
        status: undefined,
      },
      baseData: [],
      tableHeader: this.tableHeader.OrderQuery.tableHeader,
      total: 0,
      baseInfoData: {
        isShow: false,
      },
    };
  },
  computed: {
    canBeInput: function () {
      return this.condition === undefined;
    },
  },
  methods: {
    onSubmit() {
      this.formSearch.pageNo = 1;
      this.getDataList();
    },
    changeCondition() {
      this.formSearch.orderId = undefined;
      this.formSearch.appId = undefined;
      this.formSearch.workbenchUser = undefined;
    },
    onReset() {
      this.condition = undefined;
      this.changeCondition();
      this.formSearch.status = undefined;
    },
    handleCurrentChange(pageNo) {
      this.formSearch.pageNo = pageNo;
      this.getDataList();
    },
    getDataList() {
      API.payRecords(this.formSearch).then((res = {}) => {
        const {
          data: { data: { records = [], total } = {}, code, msg = "" } = {}
        } = res;
        if(code == 200 && records && records.length > 0) {
          this.baseData = records;
          this.total = total;
        }else {
          this.baseData = [];
        }
      });
    },
    openDetail(res) {
      this.baseInfoData = Object.assign(
        {
          isShow: true,
          type: "orderQuery",
        },
        res
      );
    },
  },
  created() {
    this.onReset();
    this.getDataList();
  },
};
</script>
<style lang="scss" scoped>
label {
  display: block;
  margin-bottom: 8px;
}
.d1 {
  padding: 32px;
}
.mlr {
  margin-right: 56px;
  margin-left: 12px;
}
.add {
  padding: 10px 16px;
  margin-top: 32px;
  gap: 8px;
  width: 84px;
  height: 40px;
  background: #ff9428;
  border-radius: 4px;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}
</style>