<template>
  <section class="AssetIssuance">
    <div class="d1">
      <div class="flex alignFlexEnd justifySpaceBetween">
        <div class="flex alignFlexEnd">
          <div>
            <label>查询条件</label>
            <el-select v-model="condition" placeholder="请选择">
              <el-option
                v-for="item in coditonList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="mlr">
            <el-input
              :disabled="canBeInput"
              v-model.trim="formInline[condition]"
              placeholder="选择查询条件后输入"
            ></el-input>
          </div>
          <div>
            <label>状态</label>
            <el-select v-model="formInline.status" placeholder="请选择状态">
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
    </div>
    <Table
      :baseData="baseData"
      :tableHeader="tableHeader"
      :listLoading="listLoading"
      @onClickBtn="onClickBtn"
      @handleCurrent="handleCurrentChange"
      :isShowPagination="true"
      :total="total"
      :pageSize="pageSize"
      :currentPage="pageNo"
    >
      <el-table-column label="操作" width="80" fixed="right">
        <template slot-scope="scope">
          <div class="opration check">
            <span @click="onClick(scope.row)">详情</span>
          </div>
        </template>
      </el-table-column>
    </Table>
    <detail-dialog-vue
      @close="isShowDetail = false"
      :isShowDialog="isShowDetail"
      headerText="详情"
    />
  </section>
</template>
<script>
import SearchFormVue from "./SearchForm.vue";
import DetailDialogVue from "./AssetDetailDialog.vue";
import { mapMutations } from "vuex";
import { coditonList, statusList,statusMap } from "@/util/constant";
import API from "@/api/api_query";
import dayjs from "dayjs";
export default {
  name: "AssetIssuance",
  components: {
    SearchFormVue,
    DetailDialogVue
  },
  data() {
    return {
      coditonList,
      statusList,
      statusMap,
      condition: undefined,
      formInline: {
        blockHeight: undefined,
        txHash: undefined,
        appId: undefined,
        assetId: undefined,
        status: undefined
      },
      isShowDetail: false,
      baseData: [],
      total: 0,
      pageNo: 1,
      pageSize: 10,
      isShow: false,
      listLoading: true,
      tableHeader: this.tableHeader.assetIssuance.tableHeader,
      assetNameMapCode: {
        1: "艺术品",
        2: "收藏品",
        3: "门票",
        4: "酒店"
      }
    };
  },
  computed: {
    canBeInput: function() {
      return this.condition === undefined;
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    ...mapMutations(["setDetail"]),
    onSubmit() {
      this.pageNo = 1;
      this.getDataList();
    },
    onReset() {
      this.formInline = {
        blockHeight: undefined,
        txHash: undefined,
        appId: undefined,
        assetId: undefined,
        status: undefined
      };
      this.condition = undefined;
    },
    getDataList() {
      const { blockHeight = undefined } = this.formInline || {};
      const pramas = {
        ...this.formInline,
        blockHeight: Number(blockHeight) ? Number(blockHeight) : undefined,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      API.getAssetList(pramas)
        .then((res = {}) => {
          const {
            data: { data: { records = [], total } = {}, code, msg = "" } = {}
          } = res;
          if (code === 200) {
            records.forEach(item => {
              item.createdTime =
                item.createdTime &&
                dayjs(item.createdTime).format("YYYY-MM-DD HH:mm:ss");
              item.blockTime =
                item.blockTime &&
                dayjs(item.blockTime).format("YYYY-MM-DD HH:mm:ss");
            });
            records.forEach(item => {
              item.assetDesc = this.assetNameMapCode[item.assetCate];
              item.statusDesc = statusMap[item.status];
            });
            this.baseData = records;
            this.total = total;
          } else {
            console.error(msg);
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.getDataList();
    },
    onClick(row) {
      const payload = {
        name: "assetDetail",
        detailObj: row
      };
      this.setDetail(payload);
      this.isShowDetail = true;
    },
    onClickBtn() {
      this.$emit("submit", "test");
    },
    submit() {
      this.$emit("submit", "test");
    },
    onClickClose() {
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss" scoped>
label {
  display: block;
  margin-bottom: 8px;
}
.d1 {
  padding: 32px;
}
.mlr {
  margin-right: 56px;
  margin-left: 12px;
}
.add {
  padding: 10px 16px;
  margin-top: 32px;
  gap: 8px;
  width: 84px;
  height: 40px;
  background: #ff9428;
  border-radius: 4px;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}
</style>
