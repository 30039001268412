<template>
  <div>
    <el-dialog
      append-to-body
      :title="headerText"
      :visible.sync="isShowDialog"
      :before-close="onClickClose"
      width="560px"
    >
      <ul class="ul1" v-if="rawData.length === 0">
        null
      </ul>
      <ul class="ul1" v-else>
        <li
          class="li1 flex alignCenter"
          v-for="(item, index) in rawData"
          :key="`detailList${index}`"
        >
          <label class="label">{{ `"${item.label}":` }}</label>
          <span class="value">{{ item.value }}</span>
        </li>
      </ul>
      <div class="footer" slot="footer">
        <el-button plain size="small" class="btn color_1" @click="onClickClose"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    isShowDialog: Boolean,
    headerText: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["channelDetail"]),
    rawData() {
      const rawData = this.channelDetail.rawData
        ? JSON.parse(this.channelDetail.rawData)
        : {};
      const newRawData = Object.keys(rawData).map((key) => {
        return {
          label: key,
          value: rawData[key],
        };
      });
      return newRawData;
    },
  },
  methods: {
    onClickClose() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.ul1 {
  height: fit-content;
  background: #f0f2f5;
  border-radius: 4px;
  padding: 24px;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  /* color-black-content */

  color: #616875;
}
.li1 {
  label {
    margin-right: 8px;
    min-width: 100px;
    text-align: right;
  }
  .value {
    display: inline-block;
    width: 350px;
    word-wrap: break-word;
  }
}

.footer {
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn {
  width: 96px;
  height: 36px;
  text-align: center;
  color: #333333;
  font-size: 14px;
  background: #fff !important;
}

.color_1 {
  background: rgba(37, 137, 255, 1) !important;
  color: #fff !important;
}

.text_1 {
  color: #333333;
}

.text_2 {
  color: #999999;
}

.dialog_title {
  font-size: 14px;
  font-family: "MicrosoftYaHei";
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 8px;
}
</style>
