<template>
  <section class="ChannelRequest">
    <div class="d1 padding32 flex alignFlexEnd justifySpaceBetween">
      <div>
        <label>业务请求类型</label>
        <el-select v-model="businessType" placeholder="请选择">
          <el-option
            v-for="item in chanelType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button type="primary" @click="openSearch">查询</el-button>
        <el-button @click="closeSearch">重置</el-button>
      </div>
    </div>
    <div
      v-if="canBeSearch"
      class="flex alignFlexEnd justifySpaceBetween flexWrap padding32"
    >
      <div class=" flex alignFlexEnd justifySpaceBetween">
        <div class="mr56">
          <el-radio v-model="radio" label="formInline1">按接口查看</el-radio>
          <el-select
            :disabled="radio !== 'formInline1'"
            v-model="formInline1.operateType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in apiArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="flex alignFlexEnd">
          <div>
            <el-radio v-model="radio" label="formInline2">精确搜索</el-radio>
            <el-input
              class="w256"
              :disabled="radio !== 'formInline2'"
              placeholder="请输入渠道编号"
              v-model.trim="formInline2.appId"
            ></el-input>
          </div>
          <el-input
            :disabled="radio !== 'formInline2'"
            placeholder="请输入请求编号"
            v-model.trim="formInline2.requestId"
            class="mlr24 w256"
          ></el-input>
          <el-date-picker
            :disabled="radio !== 'formInline2'"
            class="w256"
            v-model="formInline2.timeArr"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00','23:59:59']"
          />
        </div>
      </div>
      <div>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="clearForm">重置</el-button>
      </div>
    </div>
    <Table
      v-if="canBeSearch"
      :baseData="baseData"
      :tableHeader="tableHeader"
      :listLoading="listLoading"
      @handleCurrent="handleCurrentChange"
      :isShowPagination="true"
      :total="total"
      :pageSize="pageSize"
      :currentPage="pageNo"
    >
      <el-table-column label="操作" width="80" fixed="right">
        <template slot-scope="scope">
          <div class="opration check">
            <span @click="onClickDetail(scope.row)">详情</span>
          </div>
        </template>
      </el-table-column>
    </Table>
    <detail-dialog-vue
      @close="isShowDetail = false"
      :isShowDialog="isShowDetail"
      headerText="详情"
    />
  </section>
</template>
<script>
import DetailDialogVue from "./ChannelDetailDialog.vue";
import { chanelType } from "@/util/constant";
import API from "@/api/api_query";
import dayjs from "dayjs";
import { mapMutations } from "vuex";
export default {
  name: "ChannelRequest",
  components: {
    DetailDialogVue
  },
  data() {
    return {
      apiArr: [],
      chanelType,
      canBeSearch: false, //是否显示搜索和table
      businessType: undefined, //业务请求类型
      radio: "formInline1",
      value: undefined,
      formInline1: {
        operateType: undefined
      },
      formInline2: {
        appId: undefined,
        requestId: undefined,
        timeArr: []
      },
      isShowDetail: false,
      baseData: [],
      total: 0,
      pageNo: 1,
      pageSize: 10,
      isShow: false,
      listLoading: false,
      tableHeader: this.tableHeader.channelRequest.tableHeader
    };
  },
  watch: {
    radio: {
      handler(val) {
        if (val === "formInline1") {
          this.formInline2 = {
            appId: undefined,
            requestId: undefined,
            timeArr: []
          };
        } else {
          this.formInline1 = {
            operateType: undefined
          };
        }
      },
      deep: true,
      immediate: true
    },
    businessType: {
      handler(val) {
        chanelType.map((item, index) => {
          if (item.value === val) this.apiArr = item.child;
        });
        this.formInline1.operateType = undefined
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapMutations(["setDetail"]),
    onSubmit() {
      this.pageNo = 1;
      this.getDataList();
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.getDataList();
    },
    openSearch() {
      if (!this.businessType) {
        this.$message.warning("请选择业务请求类型");
        return;
      }
      this.getDataList()
      this.canBeSearch = true;
    },
    closeSearch() {
      this.businessType = undefined;
      this.canBeSearch = false;
    },
    //获取tablist
    getDataList() {
      const { timeArr } = this.formInline2;
      const pramas = {
        ...this.formInline1,
        ...this.formInline2,
        businessType: this.businessType,
        startTime: timeArr ? timeArr[0] : undefined,
        endTime: timeArr ? timeArr[1] : undefined,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        timeArr: undefined
      };
      this.listLoading = true;
      API.getChannelRecord(pramas)
        .then((res = {}) => {
          const {
            data: { data: { records, total }, code, msg = "" } = {}
          } = res;
          if (code === 200) {
            records.forEach(item => {
              item.createdTime = dayjs(item.createdTime).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              item.blockTime = dayjs(item.blockTime).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            });
            this.baseData = records;
            this.total = total;
          } else {
            console.error(msg);
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    //查看详情

    onClickDetail(row) {
      const payload = {
        name: "channelDetail",
        detailObj: row
      };
      this.setDetail(payload);
      this.isShowDetail = true;
    },
    clearForm() {
      this.formInline1 = {
        operateType: undefined
      };
      this.formInline2 = {
        appId: undefined,
        requestId: undefined,
        timeArr: []
      };
      this.pageNo = 1;
      this.pageSize = 10;
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.w256 {
  width: 256px;
}
.padding32 {
  padding: 32px;
}
label {
  display: block;
  margin-bottom: 8px;
}
.d1 {
  border-bottom: 1px solid #e3e5e8;
}
.mr56 {
  margin-right: 56px;
}
.mlr24 {
  margin: 0 24px;
}
</style>
