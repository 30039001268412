<template>
  <div>
    <el-dialog
      :visible.sync="isShow"
      :before-close="onClickClose"
      :close-on-click-modal="false"
      :title="title"
      :width="width"
      class="panel-dialog"
    >
      <ul>
        <li
          class="li1 flex alignCenter justifySpaceBetween"
          v-for="(item, index) in detailList"
          :key="`detailList${index}`"
        >
          <label>{{ item.name }}</label>
          <span
            class="export-wrap"
            v-if="sourceType == 'accountCustomer' && item.status === 'useDesc'"
          >
            <el-select
              v-model="timeRange"
              size="small"
              placeholder="请选择时间范围"
              :style="{ width: '200px !important', display: 'inline-block' }"
            >
              <el-option
                v-for="index in Object.keys(schemeTimeRange)"
                :key="index"
                :label="schemeTimeRange[index]"
                :value="index"
              ></el-option>
            </el-select>
            <span @click="exportCustomer()" class="check">导出Excel至本地</span>
          </span>
          <span v-else-if="item.type == 'img'" class="img-wrap">
            <el-image :src="item.value" :preview-src-list="[item.value]">
              <div slot="error">
                {{ $CommonMsg.imgErr }}
              </div>
            </el-image>
          </span>
          <span v-else>{{ item.value }}</span>
        </li>
      </ul>
      <div class="footer" slot="footer">
        <el-button plain size="small" class="btn color_1" @click="onClickClose"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import dayjs from "dayjs";
import API_ACCOUNT from "@/api/api_account";
import { ACCOUNT_SCHEMA, resultStatusMap } from "@/util/constant";
import { exportExcel } from "@/util/downLoad";
import { getTimeRange, formatNumber } from "@/util/common";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isShow: false,
      title: "",
      width: "50%",
      detailList: [],
      timeRange: undefined,
    };
  },
  watch: {
    item(newVal) {
      this.detailList = [];
      if (newVal) {
        this.setDialogInfo(newVal);
      }
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["deviceInfo"]),
    sourceType() {
      return this.item.type;
    },
    schemeTimeRange() {
      return ACCOUNT_SCHEMA["timeRange"] || {};
    },
  },
  methods: {
    submit() {
      this.$emit("submit", "test");
    },
    onClickClose() {
      this.isShow = false;
    },
    setDialogInfo(data) {
      this.isShow = data.isShow;
      if (this.sourceType === "accountFill") {
        this.title = "账户信息";
        this.width = "560px";
        this.setFillInfo(data);
      } else if (this.sourceType == "accountCustomer") {
        this.title = "客户账户使用详情";
        this.width = "560px";
        this.setCustomerInfo(data);
      } else if (this.sourceType == "orderQuery") {
        this.title = "详细信息";
        this.setOrderInfo(data);
      } else if (this.sourceType == "workAccount") {
        this.title = "工作台账号信息";
        this.setWorkAccount(data);
      }
    },
    setFillInfo(data) {
      this.detailList = [
        { name: "客户编号", value: data.numid },
        { name: "客户名称", value: data.name },
        { name: "统一机构代码", value: data.creditCode },
        { name: "工作台账号", value: data.workbenchAccount },
        { name: "区块链账户", value: data.account },
        { name: "渠道编号", value: data.appId },
        {
          name: "注册时间",
          value: dayjs(data.createdTime).format("YYYY-MM-DD HH:mm:ss"),
        },
        { name: "密钥方案", value: data.escrowAccount ? "托管" : "" },
        { name: "联系人姓名", value: data.contact },
        { name: "联系电话", value: data.phone },
        { name: "联系邮箱", value: data.email },
      ];
    },
    getSchemeTxt(type, key) {
      let schemeTxt = "";
      if (type == 1) {
        schemeTxt = ACCOUNT_SCHEMA["type"][key];
      } else if (type == 2) {
        schemeTxt =
          ACCOUNT_SCHEMA["spec"][this.item.chargingModel] &&
          ACCOUNT_SCHEMA["spec"][this.item.chargingModel][key];
      }
      return schemeTxt;
    },
    setCustomerInfo(data) {
      this.detailList = [
        { name: "客户编号", value: data.numid },
        { name: "客户名称", value: data.shopName },
        { name: "渠道编号", value: data.appId },
        { name: "账户方案", value: this.getSchemeTxt(1, data.chargingModel) },
        {
          name: "方案启用时间",
          value: data.createdTime ? dayjs(data.createdTime).format("YYYY-MM-DD HH:mm:ss") : '',
        },
        {
          name: "方案有效期",
          value: data.expireTime
            ? dayjs(data.expireTime).format("YYYY-MM-DD HH:mm:ss")
            : "不限",
        },
        {
          name: "方案规格",
          value:
            data.chargingModel == 2
              ? "-"
              : this.getSchemeTxt(2, data.billingScheme),
        },
        {
          name: "账户余额",
          value:
            data.chargingModel == 2
              ? "-"
              : Number(data.usableTimes || 0).toLocaleString(),
        },
        {
          name: "累计使用",
          value:
            data.chargingModel == 2
              ? "-"
              : Number(data.usedTimes || 0).toLocaleString(),
        },
        { name: "使用详情", status: "useDesc" },
      ];
    },
    setOrderInfo(data) {
      this.detailList = [
        {
          name: "发起时间",
          value: data.createdTime && dayjs(data.createdTime).format("YYYY-MM-DD HH:mm:ss"),
        },
        { name: "订单编号", value: data.orderId },
        { name: "工作台账号", value: data.workbenchAccount },
        { name: "支付用户账户", value: data.payUser },
        { name: "用户渠道编号", value: data.appId },
        { name: "支付金额", value: formatNumber(data.amount, 2) },
        { name: "支付状态", value: resultStatusMap[data.status] && resultStatusMap[data.status]["text"] || '' },
        {
          name: "支付时间",
          value: data.payTime && dayjs(data.payTime).format("YYYY-MM-DD HH:mm:ss"),
        },
        { name: "支付编号", value: data.bizId },
        { name: "支付银行编号", value: data.bankId },
        { name: "支付银行卡号", value: data.bankCardNo }
      ];
    },
    setWorkAccount(data) {
      this.width = "600px";
      API_ACCOUNT.workbenchInfo(data.id).then((res = {}) => {
        const { data: { data = {}, code, msg = "" } = {} } = res;
        if (code == 200 && data) {
          this.detailList = [
            { name: "注册手机号", value: data.phone },
            { name: "用户名称", value: data.nickName },
            { name: "账号类型", value: data.type },
            {
              name: "注册时间",
              value: data.createdTime
                ? dayjs(data.createdTime).format("YYYY-MM-DD HH:mm:ss")
                : "-",
            },
            { name: "联系邮箱", value: data.email },
            { name: "头像", value: data.headImageUrl, type: "img" },
          ];
        }
      });
    },
    exportCustomer() {
      if (!this.timeRange) {
        this.$message.warning("请选择时间范围~");
        return;
      }

      const rangeArr = this.timeRange.split("-") || [];
      const timeArr = getTimeRange(rangeArr[0], rangeArr[1]);

      exportExcel({
        filename: `客户账户使用详情-${this.schemeTimeRange[this.timeRange]}`,
        url: `${this.deviceInfo.baseUrl}/v1/admin/shop/charging/export`,
        data: {
          shopId: this.item.shopId,
          startTime: timeArr[0],
          endTime: timeArr[1],
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/_baseDialog.scss";
.export {
  margin-left: 12px;
  cursor: pointer;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;
  text-align: right;
  text-decoration-line: underline;

  /* color-blue */

  color: #337cff;
}
.w200 {
  width: 200px !important;
  height: 32px !important;
}
.li1 {
  height: auto;
  padding: 13px 0;
  border-bottom: 1px solid #e3e5e8;
  label {
    width: 120px;
    font-family: "Microsoft YaHei UI";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333a47;
  }

  > span {
    max-width: 480px;
    &.img-wrap {
      max-width: 100px;
      max-height: 100px;
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.color_1 {
  background: rgba(37, 137, 255, 1) !important;
  color: #fff !important;
}

.text_1 {
  color: #333333;
}

.text_2 {
  color: #999999;
}

.dialog_title {
  font-size: 14px;
  font-family: "MicrosoftYaHei";
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 8px;
}

.export-wrap {
  display: inline-block;
  .check {
    text-decoration: underline;
    margin-left: 12px;
  }
}

:deep() .el-image {
  @extend %com-img-cover;
  .el-image__inner {
    max-width: 100px;
    max-height: 100px;
  }
}
</style>
