import { AES, enc, mode, pad } from "crypto-js";
import axios from "axios";
import store from "../vuex/store";
export const exportExcel = (options = {}) => {
  const {
    filename,
    url,
    data,
    method = "get",
    fileType = ".xls",
    contentType = "application/json;charset=UTF-8",
  } = options;
  let pramaOrdata = method === "post" ? "data" : "params";
  // let userInfor = localStorage.getItem('perms')
  // userInfor = AES.decrypt(userInfor, "hteAdminWeb").toString(enc.Utf8);
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: url, // 请求地址
      [pramaOrdata]: data,
      responseType: "blob", // 表明返回服务器返回的数据类型
      headers: {
        "Content-Type": contentType,
        Authorization: store.getters.userInfo.authorization,
      }
    }).then(
      (response) => {
        resolve(response.data);
        let blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        let fileName = filename + fileType;
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          //释放内存
          window.URL.revokeObjectURL(link.href);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
};
