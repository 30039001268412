// import * as API from "@/utils/request";
import * as API from './request'

const comUrl = "/v1/admin/";
export default {
	//获取渠道请求
	getChannelRecord: (data) => {
		return API.post(`${comUrl}channel/record`,data);
	},
	//获取资产发行
	getAssetList: (data) => {
		return API.post(`${comUrl}asset/`,data);
	},
	//查询交易流转记录
	getTxList: (data) => {
		return API.post(`${comUrl}tx/`,data);
	},
	uploadFile: (url,data) => {
		return API.post(url,data);
	},
	payRecords(params){
		return API.get(`${comUrl}recharge`, params);
	}
}
