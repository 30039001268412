<template>
  <section class="mt-16 panel-section">
    <el-tabs class="tabs" v-model="activeName" @tab-click="tabClick">
      <el-tab-pane label="交易流转" name="first"
        ><TransactionFlow
      /></el-tab-pane>
      <el-tab-pane label="资产发行" name="second"
        ><AssetIssuance
      /></el-tab-pane>
      <el-tab-pane label="渠道请求" name="third"
        ><ChannelRequest
      /></el-tab-pane>
      <el-tab-pane label="订单查询" name="fourth">
        <OrderQuery />
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
//数字资产开放网络--查询
import dayjs from "dayjs";
import TransactionFlow from "@/components/query/TransactionFlow";
import AssetIssuance from "@/components/query/AssetIssuance";
import ChannelRequest from "@/components/query/ChannelRequest";
import OrderQuery from "@/components/query/OrderQuery.vue";

let activeName = 'first';
export default {
  name: "QueryVue",
  components: {
    TransactionFlow,
    AssetIssuance,
    ChannelRequest,
    OrderQuery
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    tabClick() {
      activeName = this.activeName;
    }
  },
  created() {
    this.activeName = activeName;
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/_basePage.scss";

$primaryColor: #337cff;
.tabs {
  /deep/ .el-tabs__active-bar {
    height: 6px;
    background: $primaryColor;
    border-radius: 28px;
  }
  /deep/ .el-tabs__item.is-active {
    color: $primaryColor;
  }
  /deep/ .el-tabs__item {
    height: 72px;
    line-height: 72px;
  }
  /deep/ .el-tabs__nav-scroll {
    padding-left: 32px;
  }
}
</style>
