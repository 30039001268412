<template>
  <div>
    <el-dialog
      append-to-body
      :title="headerText"
      :visible.sync="isShowDialog"
      :before-close="onClickClose"
      width="560px"
    >
      <ul>
        <li
          class=" li1 flex alignCenter justifySpaceBetween"
          v-for="(item, index) in detailList"
          :key="`detailList${index}`"
        >
          <label>{{ item.name }}</label>
          <span>{{ txDetail[item.props] }}</span>
        </li>
      </ul>
      <div class="footer" slot="footer">
        <el-button plain size="small" class="btn color_1" @click="onClickClose"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    isShowDialog: Boolean,
    headerText: String
  },
  data() {
    return {
      detailList: [
        { name: "请求标号", props: "requestId" },
        { name: "渠道编号", props: "appId" },
        { name: "交易哈希", props: "txHash" },
        { name: "通证编号", props: "creditCode" },
        { name: "合约地址", props: "contractAddress" },
        { name: "请求时间", props: "createdTime" },
        { name: "上链时间", props: "blockTime" },

        { name: "发起方", props: "fromAccount" },
        { name: "接收方", props: "toAccount" },
        { name: "区块高度", props: "blockHeight" }
      ]
    };
  },
  watch: {},
    computed: {
    ...mapGetters(["txDetail"]),
  },
  methods: {
    submit() {
      this.$emit("submit", "test");
    },
    onClickClose() {
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss" scoped>
.export {
  margin-left: 12px;
  cursor: pointer;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;
  text-align: right;
  text-decoration-line: underline;

  /* color-blue */

  color: #337cff;
}
.w200 {
  width: 200px !important;
  height: 32px !important;
}
.li1 {
  height: 48px;
  border-bottom: 1px solid #e3e5e8;
  label {
    width: 120px;
    font-family: "Microsoft YaHei UI";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333a47;
  }
}

.footer {
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn {
  width: 96px;
  height: 36px;
  text-align: center;
  color: #333333;
  font-size: 14px;
  background: #fff !important;
}

.color_1 {
  background: rgba(37, 137, 255, 1) !important;
  color: #fff !important;
}

.text_1 {
  color: #333333;
}

.text_2 {
  color: #999999;
}

.dialog_title {
  font-size: 14px;
  font-family: "MicrosoftYaHei";
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 8px;
}
</style>
