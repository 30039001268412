<template>
  <div>
    <el-dialog
      append-to-body
      :title="headerText"
      :visible.sync="isShowDialog"
      :before-close="onClickClose"
      width="560px"
      destroy-on-close
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="发行信息" name="inforList"></el-tab-pane>
        <el-tab-pane label="资产详情" name="detailList"></el-tab-pane>
      </el-tabs>
      <ul>
        <li
          class=" li1 flex alignCenter justifySpaceBetween"
          v-for="(item, index) in labelList"
          :key="`detailList${index}`"
        >
          <label>{{ item.name }}</label>
          <span class="value"
            >{{ assetDetail[item.props] }}
            <span v-if="item.props1">/{{ assetDetail[item.props1] }}</span>
          </span>
        </li>
      </ul>
      <div class="footer" slot="footer">
        <el-button plain size="small" class="btn color_1" @click="onClickClose"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    isShowDialog: Boolean,
    headerText: String
  },
  data() {
    return {
      activeName: "inforList",
      labelList: [],
      value1: [],
      inforList: [
        { name: "资产编号", props: "assetId" },
        { name: "渠道编号", props: "appId" },
        { name: "交易哈希", props: "txHash" },
        { name: "区块高度", props: "blockHeight" },
        { name: "合约地址", props: "contractAddress" },
        {
          name: "已生成份额/份额上限",
          props: "generateAmount",
          props1: "amount"
        },
        { name: "请求时间", props: "createdTime" },
        { name: "上链时间", props: "blockTime" },
        { name: "资产名称", props: "shopName" }
      ],

      detailList: [
        { name: "资产简称", props: "symbol" },
        { name: "资产简介", props: "shortDesc" },
        { name: "资产分类", props: "assetDesc" },
        { name: "详情描述", props: "longDesc" },
        { name: "资产标签", props: "assetExt" },
        { name: "资产原始文件", props: "assetUrl" },
        { name: "详细描述长图", props: "imgDesc" },
        { name: "状态", props: "statusDesc" }
      ]
    };
  },
  watch: {
    activeName: {
      handler(val) {
        this.labelList = this[val];
      },
      deep: true, // 深度监听
      immediate: true // 第一次初始化渲染就可以监听到
    }
  },
  computed: {
    ...mapGetters(["assetDetail"])
  },
  methods: {
    submit() {
      this.$emit("submit", "test");
    },
    onClickClose() {
      this.activeName = "inforList";
      this.$nextTick(() => {
        this.$emit("close");
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.export {
  margin-left: 12px;
  cursor: pointer;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;
  text-align: right;
  text-decoration-line: underline;

  /* color-blue */

  color: #337cff;
}
.w200 {
  width: 200px !important;
  height: 32px !important;
}
.li1 {
  min-height: 48px;
  border-bottom: 1px solid #e3e5e8;
  label {
    width: 140px;
    font-family: "Microsoft YaHei UI";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333a47;
  }
  .value {
    max-width: 400px;
  }
}

.footer {
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn {
  width: 96px;
  height: 36px;
  text-align: center;
  color: #333333;
  font-size: 14px;
  background: #fff !important;
}

.color_1 {
  background: rgba(37, 137, 255, 1) !important;
  color: #fff !important;
}

.text_1 {
  color: #333333;
}

.text_2 {
  color: #999999;
}

.dialog_title {
  font-size: 14px;
  font-family: "MicrosoftYaHei";
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 8px;
}
</style>
