<template>
  <div class="d1">
    <div class="flex alignFlexEnd justifySpaceBetween">
      <div class="flex alignFlexEnd">
        <div>
          <label>查询条件</label>
          <el-select v-model="condition" placeholder="请选择">
            <el-option
              v-for="item in coditonList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="mlr">
          <el-input
          :disabled="canBeInput"
            v-model.trim="formInline[condition]"
            placeholder="选择查询条件后输入"
          ></el-input>
        </div>
        <div>
          <label>状态</label>
           <el-select v-model="formInline.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="onReset">重置</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { coditonList,statusList } from "@/util/constant";
export default {
  props: {
    tableTitle: String,
    tableHearder: Array,
    tableData: Array,
    col: Number,
    loading: Boolean
  },
  data() {
    return {
      coditonList,
      statusList,
      condition: undefined,
      formInline: {
        blockHeight: undefined,
        txHash: undefined,
        appId: undefined,
        assetId: undefined,
        status: undefined,
      }
    };
  },
  methods: {
    addAccount() {
      this.$router.push({ name: "AddAccount" });
    },
    onSubmit() {
      this.$emit("formInline", this.formInline);
    },
    onReset() {
      this.formInline = {
       blockHeight: undefined,
        txHash: undefined,
        appId: undefined,
        assetId: undefined,
        status: undefined,
      };
      this.$emit("reset");
    }
  },
    computed:{
    canBeInput:function(){
      return this.condition === undefined
    }
  },
  created() {}
};
</script>
<style scoped>
label{
  display: block;
  margin-bottom: 8px;
}
.d1 {
  padding: 32px;
}
.mlr {
  margin-right: 56px;
  margin-left: 12px;
}
.add {
  padding: 10px 16px;
  margin-top: 32px;
  gap: 8px;
  width: 84px;
  height: 40px;
  background: #ff9428;
  border-radius: 4px;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}
</style>
